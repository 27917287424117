body{
  background-color: #f5f5f5;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  width: 100%;
}
#brands, #contact{
      min-height:80vh;
      display: -ms-flexbox;
      display: -webkit-flex;
           /* Center vertically */
           align-items: center;
           /*Center horizontaly */
          
    
}

p {
  font-family: 'Roboto', sans-serif;
}
.text {
  font-family: 'Roboto', sans-serif;
}
.animate:hover {
      -moz-transform: rotate(1deg);
      -webkit-transform: rotate(1deg);
      -o-transform: rotate(1deg);
      -ms-transform: rotate(1deg);
      transform: rotate(1deg);
}


input {
  border: none;
  width:52%;
  border-radius: 5px;
  padding:5px;
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 6px;
}
input:focus {
  background-color: #e4e4e4;
  outline-width: 0;
}
button {
  background-color: #091a2d;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #00be00;
  line-height: 1.5;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 6px;
}

/* team */
.closebtn {
  float:right;
  color: red;
  font-size: 35px;
  cursor: pointer;
} 
.container {
  position: relative;
}
.texture{
  background:#091a2d ;
  color:'white'
}
.middle {
    text-align: center;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  bottom: 0%;    
  background:
  linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
  linear-gradient(207deg, #091a2d 5px, transparent 5px) 10px 0px,
  linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
  linear-gradient(207deg, #091a2d 5px, transparent 5px) 10px 5px,
  linear-gradient(90deg, #091a2d 10px, transparent 10px),
  linear-gradient(#091a2d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);   
  background-color:#091a2d;
  background-size:14px 14px;
  width:100%;
}
.container:hover  {
  opacity: 1;
}
.container:hover .middle {
  opacity: 1;
}
.text {    
  text-align: center;
  color: white;
  font-size: 22px;
}



@media only screen and (max-width: 600px) {
  /* .teamimg1:hover {
      background: url("./assets/teama.jpg"); 
      background-size: contain;
      background-repeat: no-repeat;  
  } */
  .text {    
      display: none;
    }
    .middle {    
      display: none;
    }
}